<template>
  <div class="login-container">
    <el-form ref="loginForm" :model="loginForm" class="login-form" autocomplete="on" label-position="left">
      <div class="title-container">
        <h3 class="title">国际订货管理系统</h3>
      </div>
      <div class="form-container">
        <el-form-item prop="username">
          <el-input ref="username" v-model="loginForm.username" placeholder="请输入你的用户名" clearable name="username" type="text" size="large" tabindex="1" autocomplete="on" />
        </el-form-item>
      </div>

      <el-tooltip v-model="capsTooltip" content="Caps lock is On" placement="right" manual>
        <el-form-item prop="password">
          <el-input ref="password" v-model="loginForm.password" placeholder="请输入你的密码" size="large" clearable name="password" tabindex="2" autocomplete="on" show-password />
        </el-form-item>
      </el-tooltip>

      <el-button type="primary" :loading="loading" style="width: 100%; margin-top: 15px" size="large" @click="handleLogin">
        登录
      </el-button>
    </el-form>
  </div>
</template>

<script>
//import axios from 'axios'
import local from "element-ui/lib/locale";
import cn from "element-ui/lib/locale/lang/zh-CN";
export default {
  name: "Login",

  data() {
    return {
      loginForm: {
        username: "",
        password: "", // admin
      },
      capsTooltip: false,
      loading: false,
      showDialog: false,
    };
  },
  methods: {
    handleLogin() {
      this.$axios
        .post("/a1/admin/login", this.loginForm)
        .then((response) => {
          // 1.存储到本地
          this.$store.commit("login", response.data.data);
          this.$store.commit("createNavBar", response.data.data.rules_tree);

          // 2.成功提示
          this.$message.success("登录成功");

          //3.跳转到后台
          this.$router.push("/admin");
          document.title = "ximi-订货管理系统";
          window.sessionStorage.setItem("lg", "zh-CN");
          local.use(cn);
        })
        .catch((err) => {
          const mess = err.response.data
          this.$message.error(mess)
        });
    },
  },
};
</script>

<style lang="scss" scoped>
$bg: #2d3a4b;
$dark_gray: #889aa4;
$light_gray: #eee;
.login-container {
  width: 100%;
  min-height: 100vh;
  margin: 0px;
  background-image: url("~@/assets/images/admin_login_back.jpg");
  background-size: cover;
  background-position: center;
  position: relative;
  .login-form {
    position: relative;
    width: 520px;
    max-width: 100%;
    padding: 160px 35px 0;
    margin: 0 auto;
    overflow: hidden;
  }
  .form-container {
    margin-bottom: 30px;
  }
  .title-container {
    position: relative;
    .title {
      font-size: 26px;
      color: $light_gray;
      margin: 0px auto 20px auto;
      text-align: center;
      font-weight: bold;
    }
  }
  // .el-button {
  //   color: #fff;
  //   background-color: gray;
  //   border-color: gray;
  // }
}
</style>
